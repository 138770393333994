import React from 'react'
import whitelogo from '../mokx-white-logo.svg';
import { NavLink, Link } from "react-router-dom";
import Header from '../components/Header'
import Footer from '../components/Footer'
// import { HashLink } from 'react-router-hash-link';

function Aboutus() {
  return (
    <>
        <Header></Header>
        <section className='about-us'>
            <div className='container'>
              <div className='sec-title'>
                <div className='heding-sec'>
                  <div className='sec-heading'>
                      <p className='small-heading'>
                      Home - About Us
                      </p>
                      <p className='main-heading'>About Us
                      </p>
                  </div>
                </div>
              </div>
            </div>
        </section>

        <section className='about-main-sec-1'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6 col-xl-6 col-xxl-6'>
                  <img className='img-fluid' src={require('../images/aboutus-sec.png')} alt='Mokx Tax Service'/>
                </div>
                <div className='col-md-6 col-xl-6 col-xxl-6'>
                  <div className='about-main-sec'>
                    <div className='heding-sec'>
                      <div className='sec-heading'>
                          <p className='small-heading'>
                          ABOUT MOKX, TAX, ACCOUNTING, BOOKKEEPING
                          </p>
                          <p className='main-heading w-100'>We’re not your typical CPA firm, we’re an outsourced accounting service
                          </p>
                          
                      </div>
                    </div>
                    <p className='mt-3'>Mokx is a proudly Canadian accounting firm established in 2018. We have become a steadfast and dependable entity in the accounting realm, experiencing substantial growth alongside our clients due to our profound commitment to their achievements. Our services are meticulously customized to ensure the realization of our clients' distinct objectives. We pride ourselves on delivering high-quality, prompt, and efficient service to both residents and businesses at competitive prices.</p>
                    <p>Our professional and approachable accountants maintain intimate involvement with each client, fostering enduring working relationships. Many families and businesses, some of whom have been with us since our inception, continue to place their trust in us for both routine accounting needs and proactive advice aimed at fostering growth in their business and personal finances. By understanding each client’s specific needs and aspirations, we anticipate and capitalize on unique tax and business opportunities tailored to their circumstances.</p>
                  </div>
                </div>
              </div>
            </div>
        </section>

        <div className='container my-4'>
          <div className='static-details'>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
                <div className='static-content'>
                  <div className='static-number'>1</div>
                  <div className='static-content-inner'>
                    <p className='static-content-heading'>Spot in Google review in Accounting firm across Canada</p>
                    <p>
                    We don’t just say we provide great service.
                    we have proved it!
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
                <div className='static-content'>
                  <div className='static-number'>40</div>
                  <div className='static-content-inner' style={{margin: '0px 0px 0px 40px'}}>
                    <p className='static-content-heading' style={{margin: '0px 0px 45px 0px'}}>Enthusiastic team members</p>
                    <p>
                    Our CPA’s and CA’s are experienced and responsive.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-4 col-xl-4 col-xxl-4'>
                <div className='static-content'>
                  <div className='static-number'>10</div>
                  <div className='static-content-inner'>
                    <p className='static-content-heading'>years of services to privately held business and individuals</p>
                    <p>
                    Our approach is thoroughly modern and digital! 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className='about-main-sec-2'>
            <div className='container'>
              <div className='row'>
              <div className='col-md-6 col-xl-6 col-xxl-6'>
                  <div className='about-main-sec'>
                    <div className='heding-sec'>
                      <div className='sec-heading'>
                          <p className='small-heading'>
                          Mokx’s Values
                          </p>
                          <p className='main-heading w-100'>We’re not your typical CPA firm, we’re an outsourced accounting service
                          </p>
                          
                      </div>
                    </div>
                    <p className='mt-3'>Our core values serve as the foundation of our practice, guiding our interactions with clients, employees, and the community. We are committed to integrity, ensuring transparency and honesty in every aspect of our work. Our dedication to excellence drives us to deliver high-quality services tailored to the unique needs of each client. We foster a culture of collaboration and respect, valuing diverse perspectives and encouraging open communication for the best outcomes.</p>
                    <p>
                    Additionally, we believe in continuous improvement and innovation. By staying abreast of industry trends and advancements in technology, we provide our clients with cutting-edge solutions. Our commitment to professional development ensures our team possesses the necessary knowledge and skills to navigate the evolving financial landscape. At Mokx, we take pride in being not just accountants, but trusted advisors who empower our clients to achieve their financial goals with confidence and clarity.
                    </p>
                  </div>
                </div>
                <div className='col-md-6 col-xl-6 col-xxl-6'>
                  <img className='img-fluid' src={require('../images/aboutus-sec-2.png')} alt='Mokx Tax Service'/>
                </div>
                
              </div>
            </div>
        </section>

        <section className='our-teams'>
          <div className='container'>
            <div className='heding-sec'>
              <div className='sec-heading'>
                  <p className='small-heading'>
                  Mokx’s TEAM
                  </p>
                  <p className='main-heading w-50'>Experienced team of accountants
                  </p>
              </div>
            </div>
            <div className='teams-card'>
              <div className='row'>
                  <div className='col-md-4 col-xl-4 col-xxl-4'>
                    <div className='team-card-1'>
                      <img className='img-fluid' src={require('../images/gautam.png')} alt='Mokx Tax Service'/>
                      <div className='team-member-details'>
                        <p className='team-name'>
                        Gautam sudani
                        </p>
                        <p className='team-position'>CA (India), CS (India), B. Com</p>
                        <p className='team-role'>Managing Partner</p>
                          <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#gautam-profile">
                            View Profile <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></span>
                          </button>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 col-xl-4 col-xxl-4'>
                    <div className='team-card-2'>
                        <img className='img-fluid' src={require('../images/chirag-kapopara.png')} alt='Chirag Kapopara CPA, CA (India), B. Com'/>
                        <div className='team-member-details'>
                          <p className='team-name'>
                          Chirag kapopara
                          </p>
                          <p className='team-position'>CPA, CA (India), B. Com</p>
                          <p className='team-role'>Partner</p>
                          <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#chirag-profile">
                            View Profile <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></span>
                          </button>
                        </div>
                        
                      </div>
                  </div>
                  <div className='col-md-4 col-xl-4 col-xxl-4'>
                  <div className='team-card-3'>
                        <img className='img-fluid' src={require('../images/rohit.png')} alt='Mokx Tax Service'/>
                        <div className='team-member-details'>
                          <p className='team-name'>
                          Rohit kathiriya
                          </p>
                          <p className='team-position'>CA (India), B. Com</p>
                          <p className='team-role'>Partner</p>
                          <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#rohit-profile">
                            View Profile <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></span>
                          </button>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="modal fade" id="gautam-profile" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="gautam-profileLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div className='modal-inner'>
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div className='row'>
                        <div className='col-md-5 col-xl-5 col-xxl-5'>
                          <img className='img-fluid' src={require('../images/gautam.png')} alt='Mokx Tax Service'/>
                        </div>
                        <div className='col-md-7 col-xl-7 col-xxl-7'>
                          <div className='team-profile-details'>
                            <div className='heding-sec'>
                              <div className='sec-heading'>
                                  <p className='small-heading'>
                                  Managing Partner
                                  </p>
                                  <p className='main-heading w-100'>Gautam sudani
                                  </p>
                                  <p className='small-heading'>
                                  CA (India), CS (India), B. Com
                                  </p>
                              </div>
                            </div>
                            <div className='team-profile-discri'>
                              <p>Gautam is a confident business advisor to clients and plays an important role in helping to resolver their complex business issues.</p>
                              <p>
                              Gautam graduated from Gujarat University’s Bachelor of Commerce program in 2013 and received his CA from the Institute of Charted Accountants of India in 2016. 
                              </p>
                              <p>
                              He has experience of over a decade in the field of taxation and accounting. After gaining knowledge and experience of Canadian and US Taxation and Accounting from various firms in Canada including Deloitte. He joined Mokx as a founding partner (formerly operated under CRG Professional Services). Working with Deloitte, he has handled high net worth individual clients such as management team of Shopify, Maple Leafe Sports and Entertainment, Uber and Tesla. 
                              </p>
                              <p>
                              As the Managing Partner of Mokx, Gautam holds the vital responsibility of steering the firm’s leadership and overall management. He brings a wealth of experience to the table, providing expert tax planning and business advisory services to a diverse range of owner-managed business clients. With his deep knowledge of the construction, retail and service sectors, Gautam is adept at identifying opportunities to enhance operational efficiency. His forward-thinking approach focuses on optimizing processes for both Mokx and its clients, ensuring streamlined solutions that drive growth and success. His leadership is marked by a commitment to delivering exceptional service and fostering long-term client relationships.
                              </p>
                              <p>
                              Away from office, Gautam has volunteered his time by preparing personal income tax returns for elderly and less privileged at the CRA sponsored Income tax clinics. He loves spending time in the library, where he explores new technologies, AI developments and tax strategies. He also practices meditation regularly, which helps him stay focused and balanced.
                              </p>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn" data-bs-dismiss="modal">Close Profile <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade" id="chirag-profile" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="chirag-profileLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div className='modal-inner'>
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div className='row'>
                        <div className='col-md-5 col-xl-5 col-xxl-5'>
                          <img className='img-fluid' src={require('../images/chirag-kapopara.png')} alt='Mokx Tax Service'/>
                        </div>
                        <div className='col-md-7 col-xl-7 col-xxl-7'>
                          <div className='team-profile-details'>
                            <div className='heding-sec'>
                              <div className='sec-heading'>
                                  <p className='small-heading'>
                                  Partner
                                  </p>
                                  <p className='main-heading w-100'>Chirag kapopara
                                  </p>
                                  <p className='small-heading'>
                                  CPA, CA (India), B. Com
                                  </p>
                              </div>
                            </div>
                            <div className='team-profile-discri'>
                              <p>A problem-solver who likes taking on complex and varied issues, Chirag takes a big-picture approach to his work, thinking about issues and matters holistically. He works hard to empower his clients with information to help them and their businesses succeed. With his down-to-earth approach, Chirag works hard to relate with his clients and gain a better understanding of issues from their perspective.</p>
                              <p>
                              Chirag obtained his Bachelor of Commerce from Gujarat University in 2013 and received his CA from the Institute of Charted Accountants of India in 2015 before obtaining CPA designation in 2021.
                              </p>
                              <p>
                              Chirag brings years of experience as a consultant and assistant controller at real estate firm in GTA in accounting, corporate and personal tax, business advisory, and business knowledge to his accounting practice.  He knows that a strong understanding of costs can help businesses manage their margins better, and he helps his clients to better understand the inner workings of their businesses to become more profitable.
                              </p>
                              <p>
                              As a partner at Mokx, Chirag’s clients include a diverse portfolio of private companies that span many sectors including construction, consulting, real estate, food chains. He understands the nuances of the work found in different sectors and he makes it his goal to learn as much as possible about his clients and their businesses to deliver solutions tailored to their unique needs. Clients appreciate that he always delivers.
                              </p>
                              <p>
                              In his free time, Chirag enjoy staying active with outdoor adventures, exploring new places, and spending quality time with family and friends. He also has a passion for reading and continuously learning about the latest trends in tax and business strategy. Balancing work with personal interests keeps me energized and inspired to provide the best possible service to his clients.
                              </p>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn" data-bs-dismiss="modal">Close Profile <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade" id="rohit-profile" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="rohit-profileLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div className='modal-inner'>
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div className='row'>
                        <div className='col-md-5 col-xl-5 col-xxl-5'>
                          <img className='img-fluid' src={require('../images/rohit.png')} alt='Mokx Tax Service'/>
                        </div>
                        <div className='col-md-7 col-xl-7 col-xxl-7'>
                          <div className='team-profile-details'>
                            <div className='heding-sec'>
                              <div className='sec-heading'>
                                  <p className='small-heading'>
                                  Partner
                                  </p>
                                  <p className='main-heading w-100'>Rohit kathiriya
                                  </p>
                                  <p className='small-heading'>
                                  CA (India), B. Com
                                  </p>
                              </div>
                            </div>
                            <div className='team-profile-discri'>
                              <p>Rohit truly cares about clients and works his hardest to ensure their needs are met. His responsiveness helps them feel at ease with even the most complicated financial issues.</p>
                              <p>
                              Rohit graduated from Gujarat University’s Bachelor of Commerce program in 2013 and received his CA from the Institute of Charted Accountants of India in 2015.
                              </p>
                              <p>
                              Rohit brings a wealth of experience across key areas of financial management, including audit, finalization of accounts, GST compliance, corporate tax strategy, and accounting. His expertise spans years of hands-on involvement with diverse clients, ensuring accuracy, compliance, and strategic insight in every project. Rohit’s deep understanding of complex tax regulations, paired with his proficiency in financial reporting and auditing, allows him to offer tailored solutions that optimize business performance. His comprehensive approach ensures clients receive unparalleled service and guidance through every financial challenge.
                              </p>
                              <p>
                              Rohit provides accounting and taxation services for owner-managed enterprises operating in the manufacturing, retail, real estate, and professional service sectors, among others. His areas of expertise include personal and corporate tax planning, business start-ups, payroll and HST related matters, and providing support for disputes with the Canada Revenue Agency.
                              </p>
                              <p>
                              Rohit enjoys working with small business owners to strategize, answer questions, and form rewarding plans for the future of their businesses. Working primarily with owner managed businesses, he handles any issues that arise in an efficient and fast manner, always willing to answer questions and explain complex issues.
                              </p>
                              <p>
                              When working with Rohit, clients can trust they have someone that is dedicated to meeting their needs, knowledgeable, and responsive. He also enjoys staying active, he enjoys playing cricket and cheering for his favorite team and hanging out with family and friends. He has a passion for philanthropy, dedicating his time and resources to helping others and supporting meaningful causes.
                              </p>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn" data-bs-dismiss="modal">Close Profile <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
    </>
  )
}

export default Aboutus;